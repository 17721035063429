import React, { useEffect, useState } from 'react'
import http from '../../services/api/http'
import { useNavigate, useParams } from 'react-router'
import dayjs from 'dayjs'
import img_placeholder from '../../assets/images/placeholder/pildacil.png'

export default function DetailGallery() {

  const [data, setData] = useState(null)

  const { id,title } = useParams()
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      navigate(`/news/gallery/details/${title}`, { replace: true });
    getDetailGallery(id)
    }
  }, [id]);

  const getDetailGallery = async (id) => {
    let response = null;
    try {
      response = await http.getDetailGallery(id);
      if (response.code === 200) {
        setData(response.data)
      } else {
        console.log(response.status)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  return (
    <div className='relative pt-[68px] bg-white'>


      <div className=' w-10/12 my-[40px] mx-auto'>
        <h3 className="font-bicubik text-[14px] lg:text-[20px] text-black tracking-[4px] font-normal">{data?.title}</h3>
        <h5 className="text-[14px] lg:text-[16px] text-oxfordBlue_30 tracking-[4px] font-normal">{data?.updated_at!==null?dayjs(data?.updated_at).format('MMM DD, YYYY'):''}</h5>

        <div className='h-[24px]'></div>
        <div className=' ' dangerouslySetInnerHTML={{
        __html: data?.content
      }} />
        {/* <p className='text-[12px] lg:text-[16px]'>Lorem ipsum dolor sit amet consectetur. Blandit ultrices egestas ac aliquam. Viverra arcu nisl elementum eget et. Vitae molestie etiam a sed fusce habitant. Imperdiet magna eget arcu pharetra est.
        </p>
        <p className='my-[24px] text-[12px] lg:text-[16px]'>Lorem ipsum dolor sit amet consectetur. Eget donec malesuada purus habitasse sem non vulputate mi. Dolor vel nulla fringilla netus pharetra enim adipiscing. Non nullam eu orci augue lectus neque urna. Ornare nec tincidunt donec magna semper. A nunc auctor hendrerit integer in egestas pellentesque sed viverra. Elementum fermentum bibendum ultrices eleifend donec sed augue lectus. Tincidunt accumsan ut porttitor viverra urna. Sodales ut nullam tempor sodales. Netus sit nullam feugiat accumsan porta tortor. Phasellus et netus habitant sed donec amet arcu. Dolor sem gravida nam varius sagittis purus aliquam eget.</p> */}

        <div className='mt-[52px] grid grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-10'>

          {/* sample loop. (delete later) */}
          {data?.images.map((item, i) => (

            <div className="block bg-white" key={i}>
              <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                <img
                  className="sm:m-h-64 md:h-64 w-full object-cover"
                  src={item?.image === null ? "https://via.placeholder.com/400x242" : `${process.env.REACT_APP_IMG_URL}${item?.image}`} 
                  alt={item?.alt} 
                />
                {/* <a href="gallery/details">
                  <div
                    className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                  </div>
                </a> */}
              </div>

           
            </div>

          ))}

        </div>

        <div className='h-56 '></div>

      </div>




    </div>
  )
}