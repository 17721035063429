import axios from 'axios';
import api from '../api'

class https {
  getOnBoard = async () => {
    const res = await axios.get(`${api.host()}/web/on-board`, {
      headers: api.headers(),
    });
    return res.data;
  };

  getEvent = async req => {
    const res = await axios.post(`${api.host()}/web/event`, req, {
      headers: api.headers(),
    });
    return res.data;
  };

  getDetailEvent = async (id) => {
    const res = await axios.get(`${api.host()}/web/event/detail/${id}`, {
      headers: api.headers(),
    });
    return res.data;
  };

  getPromo = async req => {
    const res = await axios.post(`${api.host()}/web/promotion`, req, {
      headers: api.headers(),
    });
    return res.data;
  };

  getDetailPromo = async (id) => {
    const res = await axios.get(`${api.host()}/web/promotion/detail/${id}`, {
      headers: api.headers(),
    });
    return res.data;
  };

  getNews = async req => {
    const res = await axios.post(`${api.host()}/web/news`, req, {
      headers: api.headers(),
    });
    return res.data;
  };

  getDetailNews = async (id) => {
    const res = await axios.get(`${api.host()}/web/news/detail/${id}`, {
      headers: api.headers(),
    });
    return res.data;
  };
 
  getFacilities = async () => {
    const res = await axios.get(`${api.host()}/web/facilities`, {
      headers: api.headers(),
    });
    return res.data;
  };

  getAbout = async () => {
    const res = await axios.get(`${api.host()}/web/about`, {
      headers: api.headers(),
    });
    return res.data;
  };

  getPrivacyPolicy = async () => {
    const res = await axios.get(`${api.host()}/web/privacy-policy`, {
      headers: api.headers(),
    });
    return res.data;
  };

  getGallery = async req => {
    const res = await axios.post(`${api.host()}/web/gallery`, req, {
      headers: api.headers(),
    });
    return res.data;
  };
  getDetailGallery = async (id) => {
    const res = await axios.get(`${api.host()}/web/gallery/detail/${id}`, {
      headers: api.headers(),
    });
    return res.data;
  };



}

const http = new https();
export default http;
