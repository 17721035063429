import React, { useEffect, useState } from 'react'
import cover from '../../assets/images/cover/cover.png'
import http from '../../services/api/http'

export default function AboutUs() {

  const [data, setData] = useState(null)

  useEffect(() => {
    getAbout()

  }, []);

  const getAbout = async () => {
    let response = null;
    try {
      response = await http.getAbout();
      if (response.code === 200) {
        setData(response.data)
        // console.log(response.data.image)
      } 
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  
  return (
    <div className='relative pt-[64px] md:pt-[68px] bg-white'>
      <div className='h-[375px] md:h-[625px] inset-0'>
        <img
          className="absolute h-[375px] md:h-[625px] w-full"
          // src={`${process.env.REACT_APP_IMG_URL}${data?.image}`}
          
          src={data?.image === null ? cover : `${process.env.REACT_APP_IMG_URL}${data?.image}`}
          alt="theplazamillennium"
        />
        <div className='relative bg-navy/50 justify-self-end justify-center mt-[320px] md:mt-[525px] inline-flex w-full h-[52px] md:h-[100px] '>
          <h2 className='text-white text-[14p] md:text-[32px] font-normal font-bicubik self-center'>{data?.title}</h2>
        </div>
      </div>

      <div className=' w-10/12 my-[40px] mx-auto' dangerouslySetInnerHTML={{
        __html: data?.content
      }} />
      {/* <div className=' w-10/12 my-[40px] mx-auto' 
      
      >


        <p className='text-[12px] md:text-[20px]'>The Plaza Millennium yang sebelumnya dikenal dengan Pusat Belanja Plaza Millennium sebagai pusat gadget, handphone terbesar & terlengkap di SUMUT & NAD, sekarang
          dikenal dengan nama The Plaza Millennium sebagai Pusat Segala Kebutuhan Digital
          (Elektronik, HP, Komputer, Kamera, CCTV, Dll) berada di Jalan Kapten Muslim No.lll,
          Medan.
        </p>
        <p className='my-[16px] md:my-[52px] text-[12px] md:text-[20px]'>Dikelola Oleh PT Perkasa Internusa Mandiri, yang berkantor pusat di Tangerang. PT
          Perkasa Internusa Mandiri berdiri pada tahun 1999 dan saat ini dikenal sebagai Alfaland.</p>

        <p className=' text-[12px] md:text-[20px]'>Alfaland, selain bergerak dalam bidang property, juga membangun dan mengelola Hotel yang saat ini ada di Medan, Bali, Bogor, Jogjakarta dan kota lainnya</p>
        <div className="w-full my-[16px] md:my-[52px] h-[0.5px] bg-gray-300"></div>
        <p className='text-[12px] md:text-[20px]'>The Plaza Millennium, previously known as the Plaza Millennium Shopping Center as the
          largest & most complete center for gadgets, cellphones in North Sumatra & NAO, is now
          known as The Plaza Millennium as the Center for All Digital Needs (Electronics, Cell -
          phones, Computers, Cameras, CCTV, etc.). on Jalan Captain Muslim No. 111, Medan.
        </p>
        <p className='my-[16px] md:my-[52px] text-[12px] md:text-[20px]'>Managed by PT Perkasa Internusa Mandiri, which is headquartered in Tangerang. PT
          Perkasa Internusa Mandiri was founded in 1999 and is currently known as Alfaland.</p>

        <p className='  text-[12px] md:text-[20px]'>Alfaland, apart from operating in the property sector, also builds and manages hotels
          which are currently in Medan, Bali, Bogor, Jogjakarta and other cities.</p>

      </div> */}




    </div>
  )
}