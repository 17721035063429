import React, { useEffect, useState } from 'react'
import http from '../../services/api/http'
import { useNavigate, useParams } from 'react-router'
import dayjs from 'dayjs'
import cover1 from '../../assets/images/cover/bannerPressrelease1.png'
import cover2 from '../../assets/images/cover/bannerPressrelease2.png'

export default function DetailPressRealese() {

  const [data, setData] = useState(null)

  const { id,title } = useParams()
const navigate=useNavigate()

  useEffect(() => {
    if (id) {
      navigate(`/news/press-realese/details/${title}`, { replace: true });
    getDetailNews(id)
    }
  }, [id]);

  const getDetailNews = async (id) => {
    let response = null;
    try {
      response = await http.getDetailNews(id);
      if (response.code === 200) {
        setData(response.data)
      } else {
        console.log(response.status)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };


  return (
    <div className='relative pt-[68px] bg-white'>
      {/* <div className='relative h-[625px] bg-oxfordBlue inset-0'>
        <img
          className="absolute h-[625px] w-full"
          src={cover}
          alt="theplazamillennium"
        />
       
      </div> */}

      <div className=' w-10/12 my-[40px] mx-auto'>
        <h3 className="font-bicubik text-[14px] lg:text-[20px] text-black tracking-[4px] font-normal">{data?.name}</h3>
        <h5 className="text-[14px] lg:text-[16px] text-oxfordBlue_30 tracking-[4px] font-normal">{dayjs(data?.start_date).format('DD MMM YYYY')}</h5>

      
        <div className='relative h-[134px] lg:h-[407px] my-[24px] bg-oxfordBlue inset-0'>
          <img
            className="absolute h-[134px] lg:h-[407px] w-full"
            src={data?.image === null ? "https://via.placeholder.com/1440x806" : `${process.env.REACT_APP_IMG_URL}${data?.image}`}
            alt="theplazamillennium"
          />

        </div>
        <div className=' ' dangerouslySetInnerHTML={{
          __html: data?.description
        }} />

      </div>




    </div>
  )
}