import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import http from '../../services/api/http'
import dayjs from 'dayjs';
import img_placeholder1 from '../../assets/images/placeholder/pildacil.png'
import img_placeholder from '../../assets/images/placeholder/pildacil_2.png'


export default function PressRealese() {

  const [newsList, setNewsList] = useState([])
  const [latestNews, setLatestNews] = useState([])
  const [limit, setLimit] = useState(3)

  useEffect(() => {
    getNews(limit)
    getLatestNews()
  }, []);

  const getNews = async (limit) => {
    let response = null;
    const body = {
      search: '',
      limit: limit
    }
    try {
      response = await http.getNews(body);
      if (response.code === 200) {
        setNewsList(response.data)
      } else {
        console.log(response.status)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  const getLatestNews = async () => {
    let response = null;
    const body = {
      search: '',
      limit: ''
    }
    try {
      response = await http.getNews(body);
      if (response.code === 200) {
        setLatestNews(response.data)
      } else {
        console.log(response.status)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };
  return (
    <div className='flex w-full justify-center py-[60px]'>
      <div className=' w-10/12 mt-[60px]'>

        <h3 className='font-bicubik text-[20px] lg:text-[44px] text-black leading-[58px] tracking-[8.36px] font-normal'>
          Press Release
        </h3>
        <div className="w-[120px] h-2 bg-gray-300"></div>

        <div className='mt-[52px] grid grid-cols-1 lg:grid-cols-3 gap-x-10 gap-y-10'>

          <div className='col-span-2'>
            {/* <div className='h-[1420px] overflow-y-auto'> */}

            {/* sample loop. (delete later) */}
            {newsList.map((data, i) => (

              <div className="block bg-white text-center" key={i}>

                {/* tiitle */}
                <div className='font-bicubik text-[14px] lg:text-[20px] text-black tracking-[8px] font-normal w-full px-0 lg:px-[100px]'>
                  {data?.name}
                </div>
                <div className='w-full flex items-center justify-center mt-[20px]'>
                  <div className="w-[120px] h-1 bg-gray-300"></div>
                </div>

                {/* image */}
                <div className="relative overflow-hidden bg-cover bg-no-repeat mt-[44px]">
                  <img
                    className="h-74 w-full object-cover"
                    src={data?.image === null ? "https://via.placeholder.com/715x289" : `${process.env.REACT_APP_IMG_URL}${data?.image}`}
                    alt=""
                  />
                  <a href="#">
                    <div
                      className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                    </div>
                  </a>
                </div>

                {/* content */}

                <div className="py-[44px] px-[20px] text-center">
                  <div className='flex-row flex'>
                  <div className=' ' dangerouslySetInnerHTML={{
                    __html: `${data?.description.substring(0, 160)}...`
                  }} />
                  </div>
                  {/* <h5 className="text-[20px] text-black font-normal">
                    Menyambut bulan Ramadhan, The Plaza Millennium selalu menjadi Icon dalam pemilihan generasi Da’i dan Hafiz Qur’an di Sumatera Utara. Kegiatan ini menjadi kegiatan rutin setiap tahunnya yang diadakan 
                    oleh The Plaza Millennium Menyambut bulan Ramadhan...
                    </h5> */}

                  <button className='bg-transparent border border-2 border-red rounded-md text-red px-[36px] py-[16px] text-[20px] my-[44px]'>
                    <Link to={`/news/press-realese/details/${data?.id}/${data?.name.split(' ').join('-')}`}> Read More</Link>
                  </button>


                  <h5 className="mb-2 text-[14px] text-black uppercase tracking-[2px]">
                    {dayjs(data?.updated_at).format('MMM DD, YYYY')} COMMENTS BY THE PLAZA MILLENNIUM
                  </h5>
                </div>

                <div className='w-full flex items-center justify-center my-[64px]'>
                  <div className="w-full h-[1px] bg-gray-300"></div>
                </div>

              </div>

            ))}

            {/* </div> */}
          </div>
          <div className=''>
            {/* tiitle */}
            <div className='font-bicubik text-[14px] text-black tracking-[8px] font-normal w-full mb-[16px]'>
              latest press release
            </div>
            <div className="w-full h-[1px] bg-gray-300 mb-[32px]"></div>

            <div className='h-[420px] w-full overflow-y-auto'>

              {/* sample loop. (delete later) */}
              {latestNews.map((data, i) => (

                <div className="block bg-white grid grid-cols-4" key={i}>
                  <div className="relative overflow-hidden bg-cover bg-no-repeat mb-[16px]">
                    <img
                      className="h-24 w-full object-cover"
                      src={data?.image === null ? "https://via.placeholder.com/84x84" : `${process.env.REACT_APP_IMG_URL}${data?.image}`}
                      alt=""
                    />
                    <a href="#">
                      <div
                        className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                      </div>
                    </a>
                  </div>
                  
                  <div className="py-[15px] pl-[16px] col-span-3 mb-[16px]">
                    <Link to={`/news/press-realese/details/${data?.id}/${data?.name.split(' ').join('-')}`}>
                      <h5 className="font-bicubik text-[12px] text-[#FF0000] tracking-[3px] font-normal">
                        {data?.name}
                      </h5>
                    </Link>
                    <h5 className="font-bicubik text-[10px] text-black uppercase tracking-[2px] mt-1">
                      {dayjs(data?.updated_at).format('MMM DD, YYYY')} - {dayjs(data?.update_at).format('h:mm A')}
                    </h5>
                  </div>
                </div>

              ))}

            </div>
          </div>

        </div>

      </div>
    </div>
  )
}