import React from 'react'
import LogoMille from '../../assets/images/logo/LogoMille.png'
import BlueMember from '../../assets/images/blueMember.png'
import SilverMember from '../../assets/images/silverMember.png'
import GoldMember from '../../assets/images/goldMember.png'



export default function Millenation() {
  return (
    <div className='flex w-full flex-col justify-center py-[60px] '>
      <div className=' w-10/12 mt-[60px] self-center'>
        <div className='w-full flex justify-center items-center'>
          <img
            className="h-[66px] lg:h-[125px] w-auto mr-[32px] "
            src={LogoMille}
            alt="millenation"
          />
          <h3 className='font-bicubik text-[20px] lg:text-[44px] text-black font-normal'>
            Millenation
          </h3>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3' >
          <div className=' flex-col justify-center items-center px-[24px]'>
            <img
              className="h-[234px] w-auto mx-auto "
              src={BlueMember}
              alt="millenation"
            />
            <div className='bg-blueMember w-auto mt-[16px] px-[24px] py-[8px] rounded-full'>
              <h3 className='text-center text-white text-[16px] lg:text-[20px] font-bicubik'>Blue Member</h3>
            </div>

          </div>
          <div className=' flex-col justify-center items-center px-[24px]'>
            <img
              className="h-[234px] w-auto mx-auto "
              src={SilverMember}
              alt="millenation"
            />
            <div className='bg-silverMember mt-[16px] px-[24px] py-[8px] rounded-full'>
              <h3 className='text-center text-white text-[16px] lg:text-[20px] font-bicubik'>Silver Member</h3>
            </div>

          </div>
          <div className=' flex-col justify-center items-center px-[24px]'>
            <img
              className="h-[234px] w-auto mx-auto "
              src={GoldMember}
              alt="millenation"
            />
            <div className='bg-goldMember mt-[16px] px-[24px] py-[8px] rounded-full'>
              <h3 className='text-center text-white text-[16px] lg:text-[20px] font-bicubik'>Gold Member</h3>
            </div>

          </div>
        </div>

      </div>
      <div className='bg-indigoDye py-[10px] mt-[40px] mb-[32px]'>
        <h4 className='text-center text-white text-[32px] font-bicubik'>Membership Program</h4>
      </div>
      <div className='overflow-x-auto lg:overflow-hidden w-10/12  self-center'>
        <div className="w-[1240px] lg:w-full mb-[32px] h-[316px] p-6 bg-slate-100 rounded-2xl flex-col justify-start items-start gap-3 inline-flex">
          <div className="self-stretch justify-start items-start inline-flex">
            <div className="grow shrink basis-0 h-4 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-base font-bold font-['Montserrat'] leading-none">Name</div>
            </div>
            <div className="grow shrink basis-0 h-4 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-base font-bold font-['Montserrat'] leading-none">Description</div>
            </div>
            <div className="grow shrink basis-0 h-4 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-base font-bold font-['Montserrat'] leading-none">Min Purchase (IDR)</div>
            </div>
            <div className="grow shrink basis-0 h-4 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-base font-bold font-['Montserrat'] leading-none">Max Purchase (IDR)</div>
            </div>
            <div className="grow shrink basis-0 h-4 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-base font-bold font-['Montserrat'] leading-none">Multiple Point</div>
            </div>
            <div className="grow shrink basis-0 h-4 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-base font-bold font-['Montserrat'] leading-none">Period</div>
            </div>
          </div>
          <div className="self-stretch py-4 bg-white justify-start items-start inline-flex">
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">Blue</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">Blue Member</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">1</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">9.999.999</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">x1</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">365</div>
            </div>
          </div>
          <div className="self-stretch py-4 bg-white justify-start items-start inline-flex">
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">Silver</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">Silver Member</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">9.999.999</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">29.999.999</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">x2</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">365</div>
            </div>
          </div>
          <div className="self-stretch py-4 bg-white justify-start items-start inline-flex">
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">Gold</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">Gold Member</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">29.999.999</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">999.999.999</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">x2</div>
            </div>
            <div className="grow shrink basis-0 h-10 p-2 justify-center items-center gap-2 flex">
              <div className="text-center text-sky-950 text-sm font-normal font-['Montserrat'] leading-normal">365</div>
            </div>
          </div>
        </div>

      </div>
    
      <div className='bg-indigoDye py-[10px]  mb-[32px]'>
        <h4 className='text-center text-white text-[32px] font-bicubik'>Benefits</h4>
      </div>
      <div className=' w-4/6 my-[32px] self-center '>
        <ul className='list-disc'>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Member dapat mengikuti Program Millenation Poin Rewards
            berhadiah langsung.</li>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Mendapatkan Diskon di Merchant Internal & External Millenation</li>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Dapat mengikuti program-program yang diadakan di The Plaza Millennium</li>
        </ul>
      </div>
      <div className='bg-indigoDye py-[10px]  ]'>
        <h4 className='text-center text-white text-[32px] font-bicubik'>Point Redemption</h4>
      </div>
      <div className=' w-4/6 my-[32px] self-center '>
        <ul className='list-disc'>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Kumpulkan poin dengan berbelanja di Merchant Internal Millenation</li>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Member dapat menukarkan sejumlah poin yang telah dikumpulkan dengan
            hadiah yang sesuai jumlah poin</li>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Penukaran poin dengan hadiah dapat dilakukan melalui Aplikasi Millenation
            atau mendatangi langsung Bagian Informasi (CRO) The Plaza Millennium</li>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Pengambilan hadiah dilakukan di Bagian Informasi (CRO) The Plaza Millennium </li>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Penukaran poin dengan hadiah hanya dapat dilakukan 1 kali dalam 1 tahun,
            sisa poin yang ada akan otomatis hangus setelah Member melakukan
            penukaran poin berhadiah</li>
          <li className='text-oxfordBlue text-[20px] mb-[32px]'>Poin berlaku selama 1 tahun, apabila tidak ditukar selama periode yang berlangsung
            poin akan hangus secara otomatis (kembali ke angka 0)</li>
        </ul>
      </div>

    </div>
  )
}