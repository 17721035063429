import React from 'react';
import { useRoutes } from 'react-router-dom';
import Main from './layout/Main';
import Dashboard from './views/Dashboard'
import AboutUs from './views/AboutUs'
import OperationalHours from './views/OperationalHours'
import ContactUs from './views/ContactUs'
import PrivacyPolicy from './views/PrivacyPolicy'
import Millenation from './views/Millenation'
import Event from './views/WhatsOn/Event'
import EventDetail from './views/WhatsOn/DetailEvent'
import Promo from './views/WhatsOn/Promo'
import PromoDetail from './views/WhatsOn/DetailPromo'
import Galery from './views/News/Galery'
import GallerDetail from './views/News/DetailGallery'
import PressRealese from './views/News/PressRealese'
import PressRealeseDetail from './views/News/DetailPressRealese';
import MapRental from './views/Directory/MapRental';
import Facilities from './views/Directory/Facilities';


export default function Routes(isLoggedIn) {
    return useRoutes([
        {
            path: '/',
            element: <Main />,
            children: [
                { path: '', element: <Dashboard /> },
                { path: 'about-us', element: <AboutUs /> },
                { path: 'operational-hours', element: <OperationalHours /> },
                { path: 'privacy-policy', element: <PrivacyPolicy /> },
                { path: 'millenation', element: <Millenation /> },
                { path: 'contact-us', element: <ContactUs /> },
                { path: 'directory/map-rental', element: <MapRental /> },
                { path: 'directory/facilities', element: <Facilities /> },
                { path: 'whats-on/promo', element: <Promo /> },
                { path: 'whats-on/event', element: <Event /> },
                { path: 'news/gallery', element: <Galery /> },
                { path: 'news/press-realese', element: <PressRealese /> },
                { path: 'whats-on/event/details/:id?/:title', element: <EventDetail /> },
                { path: 'whats-on/promo/details/:id?/:title', element: <PromoDetail /> },
                { path: 'news/gallery/details/:id?/:title', element: <GallerDetail /> },
                { path: 'news/press-realese/details/:id?/:title', element: <PressRealeseDetail /> },
            ]
        },
        // {
        //   path: '/auth',
        //   element: <BlankLayout/>,
        //   children: [
        //     { path: 'signin', element: <Signin/> },
        //     { path: 'signup', element: <Signup/> },
        //   ]
        // },
        // {
        //   path: '/app',
        //   element:  <AppLayout/>,
        //   children: [
        //     { path: 'dashboard', element: isLoggedIn ? <Dashboard/> : <Navigate to="/auth/signin"/>},
        //     { path: 'candidat', element: isLoggedIn ? <Candidat/>: <Navigate to="/auth/signin"/> },
        //     { path: 'batch', element: isLoggedIn ? <Batch /> : <Navigate to="/auth/signin"/>},
        //     { path: 'talent', element: isLoggedIn ? <Talent />: <Navigate to="/auth/signin"/> },
        //     { path: 'curriculum', element: isLoggedIn ? <Curriculum />: <Navigate to="/auth/signin"/> },
        //     { path: 'hiring', element: isLoggedIn ? <Hiring /> : <Navigate to="/auth/signin"/> },
        //     { path: 'setting', element: isLoggedIn ? <Setting /> : <Navigate to="/auth/signin"/> },
        //   ]
        // },
        // { path: '*', element: <Navigate to="/404" replace /> }
    ]);
}