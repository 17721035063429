import React from 'react'


export default function OperationalHours() {
  return (
   <div className='flex w-full justify-center py-[60px]'>
     <div className=' w-10/12 mt-[60px]'>
       <h3 className='font-bicubik text-[20px] md:text-[44px] text-black md:leading-[58px] md:tracking-[8.36px] font-normal'>
           Operational Hours
       </h3>
       <div className="w-[74px] md:w-[120px] h-1 md:h-2 bg-gray-300"></div>
       <p className='my-[24px] md:my-[52px] text-[12px] md:text-[20px]'>Jam Operational The Plaza Millennium dari Pukul 10.00 s/d 22.00, kami selalu memberikankenyamanan berbelanja bagi pengunjung dengan menyediakan security dan kamera CCTV yang selalu stand by 24 Jam.</p>
       <p className='text-[12px] md:text-[20px]'>Dengan pengunjung rata-rata sebanyak 25.000/hari, yang merupakan potensi pasar yang cukup besar dan berkembang bagi penyewa serta harga barang yang kompetitif dan berkualitas bagi konsumen.</p>
       </div>
   </div>
  )
}