import React, { useRef, useEffect, useState } from 'react'
import Slider from 'react-slick'

import cover from '../../assets/images/cover/cover.png'
import Corousel1 from '../../assets/images/cover/Corousel1.png'
import Youtube from '../../assets/images/Youtube.svg'
import CoverYoutube1 from '../../assets/images/covercontenyoutube/img_cover1.png'
import CoverYoutube2 from '../../assets/images/covercontenyoutube/img_cover2.png'
import CoverYoutube1_New from '../../assets/images/covercontenyoutube/YOUTUBE_1_WEB.jpg'
import CoverYoutube2_New from '../../assets/images/covercontenyoutube/YOUTUBE_2_WEB.jpg'
import Promo1 from '../../assets/images/promo/img_promo1.png'
import Promo2 from '../../assets/images/promo/img_promo2.png'
import Event1 from '../../assets/images/event/img_event1.png'
import Event2 from '../../assets/images/event/img_event2.png'

import ArrowRight from '../../assets/images/circleArrowRight.svg'
import { Link } from 'react-router-dom'
import http from '../../services/api/http'





export default function Dashboard() {
  let sliderBannerRef = useRef(null);
  let sliderPromoRef = useRef(null);
  let sliderEventRef = useRef(null);

  const nextBanner = () => {
    sliderBannerRef.slickNext();
  };
  const previousBanner = () => {
    sliderBannerRef.slickPrev();
  };

  const nextPromo = () => {
    sliderPromoRef.slickNext();
  };
  const previousPromo = () => {
    sliderPromoRef.slickPrev();
  };

  const nextEvent = () => {
    sliderEventRef.slickNext();
  };
  const previousEvent = () => {
    sliderEventRef.slickPrev();
  };

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    // cssEase: "linear",
    centerPadding: '100px',
    pauseOnHover: true
  };

  const settingsPromo = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // infinite: true,
          // dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          // initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const data = [
    {
      img: Corousel1,
    },
    {
      img: Promo1,
    },
    {
      img: Promo2,
    },
    {
      img: Event1,
    },
    {
      img: Event2,
    },

  ];

  const [eventList, setEventList] = useState([])
  const [promoList, setPromoList] = useState([])
  const [onBoardList, setOnBoardList] = useState([])

  useEffect(() => {
    getEvent(6)
    getPromo(6)
    getonBoard()
  }, []);

  const getEvent = async (limit) => {
    let response = null;
    const body = {
      search: '',
      limit: limit
    }
    try {
      response = await http.getEvent(body);
      if (response.code === 200) {
        setEventList(response.data)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  const getPromo = async (limit) => {
    let response = null;
    const body = {
      search: '',
      limit: limit
    }
    try {
      response = await http.getPromo(body);
      if (response.code === 200) {
        setPromoList(response.data)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  const getonBoard = async () => {
    let response = null;
    try {
      response = await http.getOnBoard();
      if (response.code === 200) {

        setOnBoardList(response.data)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  return (
    <div className='relative w-full bg-white overflow-hidden'>

      <div className="mt-[64px] md:mt-[69px] w-full h-[214px]md:h-[800px] ">
        <Slider
          ref={slider => {
            sliderBannerRef = slider;
          }}
          {...settings}>
          {onBoardList.map((d) => (
            <div key={d.id} className="bg-white w-full h-[214px]md:h-[800px] text-black  ">
              <div className='h-[214px]md:h-[800px] w-full flex justify-center items-center '>
                <img src={d?.image === null ? "https://via.placeholder.com/1440x806" : `${process.env.REACT_APP_IMG_URL}${d?.image}`}
                  alt="" className="h-[214px] md:h-[500px] lg:h-[800px] w-full" />
              </div>

            </div>
          ))}
        </Slider>


        <div className='absolute inset-0 items-center justify-start h-[220px] md:h-[510px] lg:h-[810px] mt-[64px] md:mt-[69px] flex w-full'>
          <div className='relative justify-self-center justify-between mx-[30px] md:mx-[76px]  inline-flex w-full  '>
            <button onClick={previousBanner}>
              <img
                className="h-[24px] md:h-[80px] w-auto rotate-180"
                src={ArrowRight}
                alt="theplazamillennium"
              />
            </button>
            <button onClick={nextBanner}>
              <img
                className="h-[24px] md:h-[80px] w-auto"
                src={ArrowRight}
                alt="theplazamillennium"
              />
            </button>
          </div>
        </div>
      </div>



      {/* <div className=' h-[800px] pt-[69px] '>
        
        <img
          className="absolute  w-full object-cover"
          src={Corousel1}
          alt="corousel"
        />
      </div> */}


      <div className='h-[214px] md:h-[620px] overflow-hidden'>
        <img
          className="absolute h-[214px] md:h-[625px] w-full"
          src={cover}
          alt="theplazamillennium"
        />
        <div className='absolute bg-navy/50 justify-self-end justify-center  h-[214px] md:h-[625px] inline-flex w-full pb-[64px] '></div>
        <div className='relative justify-self-end justify-center mt-[130px] md:mt-[500px] inline-flex w-full pb-[64px]  '>
          <div className='w-full flex flex-col '>
            <h2 className='text-white text-[16px] md:text-[32px] font-normal font-bicubik self-center'>ABOUT THE PLAZA MILLENNIUM</h2>
            <h2 className='text-white text-[12px] md:text-[20px] text-center font-normal self-center'>Now You Can Eat, Meet and Shop<br />
              Beyond Your Imagination</h2>

          </div>
        </div>
      </div>



      <div className='flex-col justify-start items-center gap-4 flex my-10'>
        <h5 className='text-black text-[16px] md:text-[20px] font-bold  self-center'>The Plaza Millennium</h5>
        <div className='inline-flex self-center border rounded-lg'>
          <Link to={'https://www.youtube.com/@ThePlazaMillennium2024?sub_confirmation=1'} target="_blank">
          <div className='bg-red inline-flex py-2 px-[16px] rounded-lg gap-2 '>
            <img
              className="h-[24px] w-[24px] md:h-[32px] md:w-[32px] "
              src={Youtube}
              alt="theplazamillennium"
            />
            <p className='text-white text-[12px] md:text-[20px] font-normal  self-center'> YouTube</p>
          </div>
          </Link>
          {/* <div className='bg-white inline-flex py-2 px-[16px] rounded-r-lg gap-2 shadow-xl'  >
            <p className='text-black text-[12px] md:text-[20px] font-normal  self-center'>2 RB</p>
          </div> */}
        </div>
      </div>

      <div className='flex container w-full justify-center mx-auto py-[32px] md:py-[60px]'>
        <div className='w-full justify-evenly inline-flex flex-wrap gap-x-20 lg:gap-x-2 '>
          {/* <Link to={'https://www.youtube.com/watch?v=GWI6EIWClkk'} target="_blank"> */}
          <Link to={'https://www.youtube.com/watch?v=Xh7uyHDRglY&t=5s'} target="_blank">
            <div className="w-[335px] h-[189px] md:w-[490px] md:h-[280px] xl:w-[610px] xl:h-[343px] relative rounded-lg">
              <img className="w-[335px] h-[189px] md:w-[490px] md:h-[280px] xl:w-[610px] xl:h-[343px] left-0 top-0 absolute rounded-lg" src={CoverYoutube1_New}
              // "https://via.placeholder.com/610x343" 
              />
              <div className="w-[43.01px] h-[43.51px] left-[283.14px] top-[155.91px] absolute bg-black/opacity-60 rounded-[40px]"></div>
            </div>
          </Link>
          {/* <Link to={'https://www.youtube.com/watch?v=-QmCnINyFGg'} target="_blank"> */}
          <Link to={'https://www.youtube.com/watch?v=9inaW0FiZk4'} target="_blank">
            <div className="w-[335px] h-[189px] md:w-[490px] md:h-[280px] xl:w-[610px] xl:h-[343px] relative rounded-lg">
              <img className="w-[335px] h-[189px] md:w-[490px] md:h-[280px] xl:w-[610px] xl:h-[343px] left-0 top-0 absolute rounded-lg" src={CoverYoutube2_New}
              // "https://via.placeholder.com/610x343"
              />
              <div className="w-[43.01px] h-[43.51px] left-[283.14px] top-[155.91px] absolute bg-black/opacity-60 rounded-[40px]"></div>
            </div>
          </Link>
        </div>
      </div>

      <div className='flex justify-center'>
        <h5 className='text-black text-[32px] font-normal font-bicubik self-center'>Promo</h5>
      </div>

      <div className="relative container mx-auto py-[60px]">
        <Slider
          ref={slider => {
            sliderPromoRef = slider;
          }}
          {...settingsPromo}>
          {promoList.map((data, index) => (
            <div key={index} className=''>

              <div className='w-[335px] h-[225px] md:w-[490px] md:h-[280px] xl:w-[610px] xl:h-[343px] w-11/12 mx-auto'>

                <Link to={`whats-on/promo/details/${data?.id}`} >
                  <img className="w-[335px] h-[189px] md:w-[490px] md:h-[280px] xl:w-[610px] xl:h-[343px]  absolute rounded-lg" src={data?.image === null ? "https://via.placeholder.com/610x343" : `${process.env.REACT_APP_IMG_URL}${data.image}`}
                  // src="https://via.placeholder.com/610x343" 
                  />
                  {/* <a href="whats-on/promo/details"> */}
                  {/* <div
                    className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                    {data?.image}
                  </div> */}
                  {/* </a> */}
                </Link>

              </div>
              <div className='flex justify-center mt-4'>
                <h5 className='text-black text-[14px] font-normal font-bicubik self-center'>{data?.name}</h5>
              </div>


            </div>
          ))}
        </Slider>

        {promoList.length > 1 ?
          <div className=' absolute items-center justify-center -mt-[160px] md:-mt-[180px] xl:-mt-[220px] inline-flex w-full  '>
            <div className='relative justify-self-center justify-between mx-[90px] sm:mx-[135px] md:mx-[125px]  lg:mx-[0px]  inline-flex w-full  '>
              <button onClick={previousPromo}>
                <img
                  className="h-[40px] lg:h-[60px] w-auto rotate-180"
                  src={ArrowRight}
                  alt="theplazamillennium"
                />
              </button>
              <button onClick={nextPromo}>
                <img
                  className="h-[40px] lg:h-[60px] w-auto"
                  src={ArrowRight}
                  alt="theplazamillennium"
                />
              </button>
            </div>
          </div>
          :
          null
        }

      </div>

      <div className='flex justify-center'>
        <h5 className='text-black text-[32px] font-normal font-bicubik self-center'>Event</h5>
      </div>

      <div className="relative container mx-auto py-[60px]">
        <Slider
          ref={slider => {
            sliderEventRef = slider;
          }}
          {...settingsPromo}>
          {eventList.map((data, index) => (
            <div key={index} className=''>

              <div className='w-[335px] h-[225px] md:w-[490px] md:h-[280px] xl:w-[610px] xl:h-[343px] w-11/12 mx-auto'>

                <Link to={`whats-on/event/details/${data?.id}`} >
                  <img className="w-[335px] h-[189px] md:w-[490px] md:h-[280px] xl:w-[610px] xl:h-[343px]  absolute rounded-lg" src={data?.image === null ? "https://via.placeholder.com/610x343" : `${process.env.REACT_APP_IMG_URL}${data.image}`}
                  // src="https://via.placeholder.com/610x343" 
                  />
                  {/* <a href="whats-on/event/details"> */}
                  {/* <div
                   className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                   {data.image}
                 </div> */}
                  {/* </a> */}
                </Link>

              </div>
              <div className='flex justify-center mt-4'>
                <h5 className='text-black text-[14px] font-normal font-bicubik self-center'>{data?.title}</h5>
              </div>


            </div>
          ))}


        </Slider>
        {eventList.length > 1 ?
          <div className=' absolute items-center justify-center -mt-[160px] md:-mt-[180px] xl:-mt-[220px] inline-flex w-full  '>
            <div className='relative justify-self-center justify-between mx-[90px] sm:mx-[135px] md:mx-[125px]  lg:mx-[0px]  inline-flex w-full  '>
              <button onClick={previousEvent}>
                <img
                  className="h-[40px] lg:h-[60px] w-auto rotate-180"
                  src={ArrowRight}
                  alt="theplazamillennium"
                />
              </button>
              <button onClick={nextEvent}>
                <img
                  className="h-[40px] lg:h-[60px] w-auto"
                  src={ArrowRight}
                  alt="theplazamillennium"
                />
              </button>
            </div>
          </div>
          :
          null
        }

      </div>


      <div className='h-24'></div>

    </div>
  )
}