import React from 'react'
import img_placeholder from '../../assets/images/placeholder/pildacil.png'
import Maps from '../../assets/images/Maps.png'
import Rental1 from '../../assets/images/rental1.png'
import Rental2 from '../../assets/images/rental2.png'
import Contact from '../../assets/images/contact.svg'
import Fax from '../../assets/images/fax.svg'


export default function MapRental() {
  return (
    <div className='flex w-full justify-center py-[60px]'>
      <div className=' w-10/12 mt-[60px]'>
        <h3 className='font-bicubik text-[20px] lg:text-[44px] text-black leading-[58px] tracking-[8.36px] font-normal'>
          Map & Rental
        </h3>
        <div className="w-[74px] lg:w-[120px] h-1 lg:h-2 bg-gray-300"></div>
        <div className="h-[24px] lg:h-[60px]"></div>
        <div >
          <h3 className='font-bicubik text-[16px] lg:text-[32px] text-black leading-[58px] tracking-[8.36px] font-normal'>
            Map
          </h3>
          <div className="w-[24px] lg:w-[80px] h-1 bg-gray-300"></div>
          <div className="w-full h-[152px] md:h-[351px] lg:h-[561px] mt-[16px] lg:mt-[40px] relative rounded-lg ">
            <img className="w-full h-[152px] md:h-[351px] lg:h-[561px] left-0 top-0 absolute "
              src={Maps}
            //  "https://via.placeholder.com/1240x561" 
            />
          </div>

        </div>
        <div className='h-[92px]'></div>

        {/* rental */}

        <div>
          <h3 className='font-bicubik text-[16px] lg:text-[32px] text-black leading-[58px] tracking-[8.36px] font-normal'>
            Rental
          </h3>
          <div className="w-[80px] h-1 bg-gray-300"></div>
          <div className='h-[40px]'></div>
          {/* <div className='w-full h-[478px] p-10 bg-white rounded-lg border border-gray-300 flex-col justify-start items-start '>

          </div> */}

          <div className="w-full  p-10 bg-white rounded-lg border border-gray-300 flex-col justify-start items-start gap-7 inline-flex">
            <div className="justify-start w-full md:w-3/4 items-center gap-2 inline-flex">
              <div className="w-2 self-stretch bg-sky-950"></div>
              <h3 className=" text-zinc-900 text-[14px] md:text-xl font-normal font-bicubik ">INFO RENTAL COUNTER DAN PAMERAN (EXHIBITION)</h3>
            </div>
            <div className="self-stretch justify-start items-start gap-10 flex-col lg:flex-row lg:inline-flex">
              <div className="w-full lg:w-[462px] h-[227px] lg:h-[346px] relative rounded-lg">
                <img className="w-full lg:w-[462px] h-[227px] lg:h-[346px] rounded-lg left-0 top-0 absolute" src={Rental1}
                // "https://via.placeholder.com/462x346" 
                />
              </div>
              <div className='h-[16px] lg:h-[0px]'></div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
                <h4 className="self-stretch text-zinc-900 text-[12px] md:text-sm font-normal font-['Montserrat'] leading-normal">Millennium ICT Center memberikan beberapa pilihan tempat usaha anda yang berupa Counter dan Exhibition (Pameran di Atrium). Rata-rata tingkat occupancy counter & pameran adalah 90%.</h4>
                <div className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Info Harga sewa lokasi Pameran dan Counter</div>
                <ul className="list-decimal m-[10px] flex-col justify-start items-start gap-2 flex">
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Harga mulai dari Rp. 50.000,-/M2/Hari (Exclude PPN)</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Minimum luas sewa lokasi 4 M2</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Listrik Free 2 Ampere</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Sewa Lokasi diatas 15M2 Free Listrik 4 Ampere</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Minimum lama sewa 2 Hari untuk Pameran (Exhibition di Atrium)</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Minimum lama sewa 2 Hari untuk Pameran (Exhibition di Atrium)</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Penambahan arus listrik Rp. 6050,-/Ampere/Hari</li>
                </ul>
              </div>
            </div>
          </div>

          <div className='h-[40px]'></div>

          <div className="w-full  p-10 bg-white rounded-lg border border-gray-300 flex-col justify-start items-start gap-7 inline-flex">
          <div className="justify-start w-full md:w-3/4 items-center gap-2 inline-flex">
              <div className="w-2 self-stretch bg-sky-950"></div>
              <h3 className=" text-zinc-900 text-[14px] md:text-xl font-normal font-bicubik ">RENTAL INFO SEWA LOKASI KIOS</h3>
            </div>
            <div className="self-stretch justify-start items-start gap-10 flex-col lg:flex-row lg:inline-flex">
              <div className="w-full lg:w-[462px] h-[227px] lg:h-[346px] relative rounded-lg">
                <img className="w-full lg:w-[462px] h-[227px] lg:h-[346px] rounded-lg left-0 top-0 absolute" src={Rental2}
                // "https://via.placeholder.com/462x346" 
                />
              </div>
              <div className='h-[16px] lg:h-[0px]'></div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-4 inline-flex">
                <h4 className="self-stretch text-zinc-900 text-[12px] md:text-sm font-normal font-['Montserrat'] leading-normal">Millennium ICT Center menyediakan lebih Kurang 392 Kios yang ukuran luasnya bervariatif mulai dari 7,5 M2 dengan posisi yang kebanyakan strategis untuk usaha anda.</h4>
                
                <ul className="list-decimal m-[10px] flex-col justify-start items-start gap-2 flex">
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Harga Mulai Rp.115.000/M2/bulan (tergantung posisi kios)</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Biaya Maintenance Rp. 69.500,-/M2/Bulan</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Biaya Penerangan Rp. 155.000,-/Ampere/Bulan (Minimal Pemakaian 4 Ampere)</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Kondisi Kios Masih Kosong</li>
                  <li className="self-stretch text-zinc-900 text-sm font-normal font-['Montserrat'] leading-normal">Free Parkir 1 Unit Mobil dan 1 Unit Sepeda Motor selama Setahun</li>

                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* rental */}
        <div className='h-[40px]'></div>
        <div className="full  p-10 bg-indigoDye rounded-lg flex-col justify-start items-start gap-5 ">
          <h3 className="text-white text-xl font-bold leading-normal mb-4 ">Informasi Rental Kios dan Pameran :</h3>
          <div className="justify-evenly items-start gap-6 lg:gap-[0px] lg:flex-row flex-col flex">
            <div className="flex-col justify-start items-start gap-1 inline-flex">
              <h3 className="text-white text-base font-bold leading-none">Gedung Millennium ICT Center</h3>
              <h5 className="text-white text-sm font-normal  leading-normal">Jalan Kapten Muslim No 111 Lantai 6 Medan, Sumatera Utara - 20123</h5>
            </div>
            <div className="justify-start items-center gap-4 flex">
              <div className="w-10 h-10 relative">
                <img
                  className="h-8 w-auto sm:h-10"
                  src={Contact}
                  alt="contact"
                />
              </div>
              <div className="flex-col justify-start items-start gap-1 inline-flex">
                <div className="text-white text-base font-bold  leading-none">Telpon</div>
                <div className="text-white text-sm font-normal  leading-normal">061-8452111 ext 16, 19</div>
              </div>
            </div>
            <div className="justify-start items-center gap-4 flex">
              <div className="w-10 h-10 relative">
                <img
                  className="h-8 w-auto sm:h-10"
                  src={Fax}
                  alt="fax"
                />
              </div>
              <div className="flex-col justify-start items-start gap-1 inline-flex">
                <div className="text-white text-base font-bold leading-none">Fax</div>
                <div className="text-white text-sm font-normal  leading-normal">061-8457111</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}