import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import http from '../../services/api/http'


export default function Event() {
  const [eventList, setEventList] = useState([])
  const [limit,setLimit]=useState(6)

  useEffect(() => {
    getEvent(limit)
  }, []);

  useEffect(() => {
    getEvent(limit)
  }, [limit]);
  

  const getEvent = async (limit) => {
    let response = null;
    const body = {
      search: '',
      limit: limit
    }
    try {
      response = await http.getEvent(body);
      if (response.code === 200) {
        setEventList(response.data)
      } else {
        console.log(response.status)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  return (
    <div className='flex w-full justify-center py-[60px]'>
    <div className=' w-10/12 mt-[60px]'>
      <h3 className='font-bicubik text-[44px] text-black leading-[58px] tracking-[8.36px] font-normal'>
        Event
      </h3>
      <div className="w-[120px] h-2 bg-gray-300"></div>

      <div className='mt-[52px] grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-10'>

        {/* sample loop. (delete later) */}
        {eventList.map((item, i) => (
          
          <div className="block bg-white" key={i}>
            <div className="relative  overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
              <img 
                className="sm:m-h-64 md:h-64 w-full object-cover" 
                // src={item.image}
                src={item?.image === null ? "https://via.placeholder.com/400x242" : `${process.env.REACT_APP_IMG_URL}${item.image}`} 
                alt="" 
              />
               <Link to={`details/${item?.id}/${item?.title.split(' ').join('-')}`} >
              {/* <a href="event/details"> */}
                <div
                  className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                </div>
              {/* </a> */}
              </Link>
            </div>

            <div className="py-[24px]  mt-[8px] text-center">
              <h5 className="font-bicubik text-[16px] text-black tracking-[4px] font-normal">
                {item?.title}
              </h5>
              <div className="w-[120px] h-1 mx-auto my-[16px] self-center bg-gray-300"></div>
            </div>
          </div>

        ))}

      </div>
      {
        eventList>6?
        <div className='w-full mt-[52px] flex items-center justify-center'>
        <button onClick={()=>setLimit(limit+6)} className='bg-white border border-2 border-red rounded-md text-red px-[36px] py-[16px] text-[20px]'>
          Load More
        </button>
      </div>
      :
      null

      }
      
      
     

    </div>
  </div>
  )
}