import React, { useEffect, useState } from 'react'
import http from '../../services/api/http'
import { useNavigate, useParams } from 'react-router'
import cover from '../../assets/images/cover/bannerEvent.png'

export default function DetailEvent() {

  const [data, setData] = useState(null)

  const { id,title } = useParams()

  const navigate = useNavigate();
 
  useEffect(() => {
    if (id) {
      navigate(`/whats-on/event/details/${title}`, { replace: true });
      getDetailEvent(id)
    }
   

  }, [id]);

  const getDetailEvent = async (id) => {
    console.log(id)
    let response = null;
    try {
      response = await http.getDetailEvent(id);
      if (response.code === 200) {
        setData(response.data)
      } else {
        console.log(response.status)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  return (
    <div className='relative pt-[68px] bg-white w-full'>
      <div className='relative h-[210px] lg:h-[625px] bg-oxfordBlue inset-0'>
        <img
          className="absolute h-[210px] lg:h-[625px] w-full"
          src={data?.image === null ? "https://via.placeholder.com/1440x806" : `${process.env.REACT_APP_IMG_URL}${data?.image}`} 
          alt="theplazamillennium"
        />
      </div>

      <div className=' w-10/12 my-[40px] mx-auto'>
          <h3 className="font-bicubik text-[14px] lg:text-[20px] text-black tracking-[4px] font-normal">{data?.title}</h3>
          <h5 className="text-[14px] lg:text-[16px] text-oxfordBlue_30 tracking-[4px] font-normal">{data?.sub_title}</h5>

        <div className='h-[24px]'></div>
        <div className=' ' dangerouslySetInnerHTML={{
        __html: data?.content
      }} />
        {/* <p className='text-[12px] lg:text-[16px]'>Lorem ipsum dolor sit amet consectetur. Blandit ultrices egestas ac aliquam. Viverra arcu nisl elementum eget et. Vitae molestie etiam a sed fusce habitant. Imperdiet magna eget arcu pharetra est.
        </p>
        <p className='my-[24px] text-12 lg:text-[16px]'>Lorem ipsum dolor sit amet consectetur. Eget donec malesuada purus habitasse sem non vulputate mi. Dolor vel nulla fringilla netus pharetra enim adipiscing. Non nullam eu orci augue lectus neque urna. Ornare nec tincidunt donec magna semper. A nunc auctor hendrerit integer in egestas pellentesque sed viverra. Elementum fermentum bibendum ultrices eleifend donec sed augue lectus. Tincidunt accumsan ut porttitor viverra urna. Sodales ut nullam tempor sodales. Netus sit nullam feugiat accumsan porta tortor. Phasellus et netus habitant sed donec amet arcu. Dolor sem gravida nam varius sagittis purus aliquam eget.</p>

        <p className=' text-12 lg:text-[16px]'>Lorem ipsum dolor sit amet consectetur. Eget donec malesuada purus habitasse sem non vulputate mi. Dolor vel nulla fringilla netus pharetra enim adipiscing. Non nullam eu orci augue lectus neque urna. Ornare nec tincidunt donec magna semper. A nunc auctor hendrerit integer in egestas pellentesque sed viverra. Elementum fermentum bibendum ultrices eleifend donec sed augue lectus. Tincidunt accumsan ut porttitor viverra urna. Sodales ut nullam tempor sodales. Netus sit nullam feugiat accumsan porta tortor. Phasellus et netus habitant sed donec amet arcu. Dolor sem gravida nam varius sagittis purus aliquam eget.</p>
      
        <div className='h-[24px]'></div>
        <h5 className="text-[12px] lg:text-[16px] text-black tracking-[4px] font-semibold">Lorem ipsum dolor sit</h5>
        <p className='text-[12px] lg:text-[16px] mt-[8px]'>Lorem ipsum dolor sit amet consectetur. Eget donec malesuada purus habitasse sem non vulputate mi. Dolor vel nulla fringilla netus pharetra enim adipiscing. Non nullam eu orci augue lectus neque urna. Ornare nec tincidunt donec magna semper. A nunc auctor hendrerit integer in egestas pellentesque sed viverra. Elementum fermentum bibendum ultrices eleifend donec sed augue lectus. Tincidunt accumsan ut porttitor viverra urna. Sodales ut nullam tempor sodales. Netus sit nullam feugiat accumsan porta tortor. Phasellus et netus habitant sed donec amet arcu. Dolor sem gravida nam varius sagittis purus aliquam eget.
        </p> */}
      
      </div>




    </div>
  )
}