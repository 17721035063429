import React, { useEffect, useState } from 'react'
import http from '../../services/api/http'


export default function PrivacyPolicy() {

  const [data, setData] = useState(null)

  useEffect(() => {
    getPrivacyPolicy()

  }, []);

  const getPrivacyPolicy = async () => {
    let response = null;
    try {
      response = await http.getPrivacyPolicy();
      if (response.code === 200) {
        setData(response.data)
      } else {
        console.log(response.status)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  return (
    <div className='flex w-full flex justify-center py-[60px] '>
    <div className=' w-10/12 mt-[60px]'>
      <h3 className='font-bicubik text-[20px] md:text-[44px] text-black leading-[58px] tracking-[8.36px] font-normal'>
          Privacy & Policy
      </h3>
      <div className="w-[74px] md:w-[120px] h-1 md:h-2  bg-gray-300"></div>
      <div className=' w-10/12 my-[40px] mx-auto' dangerouslySetInnerHTML={{
        __html: data?.privacy_policy
      }} />
      <p className='my-[24px] md:my-[52px] text-[12px] md:text-[20px]'>Kebijakan Privacy berikut menjelaskan bagaimana kami mengumpulkan, menyimpan, menggunakan, mentfansfer, mengungkapkan dan melindungi informasi pribadi Anda yang dapat diidentifikasi dan diperoleh melalui Aplikasi kami (sebagaimana didefinisikan di bawah). Silahkan baca Kebijakan Privasi ini secara menyeluruh untuk memastikan bahwa Anda memahami bagaimana Kami menerapkan Kebijakan Privasi ini.</p>
      <p className='text-[12px] md:text-[20px]'>Kebijakan Privasi ini dimasukkan sebagai bagian dari Ketentuan Penggunaan Kami. Kebijakan Privasi ini mencakup hal-hal berikut:</p>
      </div>
  </div>
  )
}