import React, { useEffect } from "react";
import {  Outlet, useLocation } from "react-router-dom";
import Footer from "./Footer";
import Navbar from "./Navbar";

const Main = () => {
    const location=useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [location])

    return (
        <div className='flex flex-col h-screen '>
            <Navbar/>
            <main>
              
                <div className="flex flex-grow w-full">
                    <Outlet />
                </div>
            </main>
            <Footer/>
        </div>
    )
}

export default Main