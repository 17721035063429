import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from '../assets/images/logo/Logo.png'
import Menu from '../assets/images/menuBurger.svg'
import Close from '../assets/images/Close.svg'
import Arrow from '../assets/images/arrowRight.svg'
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { useLocation } from 'react-router-dom';

const Navbar = () => {
    const location = useLocation();
    const [isOpenMenu, setIsOpenMenu] = useState(false)
    const [heading, setHeading] = useState('')

    const toggleMenu = () => {
        setIsOpenMenu(!isOpenMenu)
        setHeading('')
    }

    const menu = {

    }



    return (
        <nav className="w-full fixed z-10  bg-oxfordBlue text-gray-200 shadow-lg navbar navbar-expand-lg navbar-light">


            <div className=" w-full lg:w-11/12 lg:mx-auto px-[20px] lg:px-2 flex justify-between lg:justify-center ">
                <div className='py-4'>
                    <Link to="/">
                        <span className="sr-only">theplazamillennium</span>
                        <img
                            className="h-8 w-auto sm:h-10"
                            src={Logo}
                            alt="theplazamillennium"
                        />
                    </Link>
                </div>
                {/* menu dekstop */}
                <div className="hidden lg:flex flex-grow md:flex-wrap md:justify-end ">
                    {/* <input type="search" className="w-full  mt-1 p-1 md:mt-0 md:w-full md:mx-4 rounded-lg" placeholder="Search"></input> */}
                    <ul className="hidden  md:flex md:justify-end  lg:w-5/6 list-style-none">
                        <li className="py-6 flex justify-center text-center hover:bg-indigoDye px-4 md:text-[14px] xl:text-[16px] font-bicubik hover:font-semibold hover:text-gray-400"><Link to={'/'} href="#">HOME</Link></li>
                        <li className="py-6 flex justify-center text-center hover:bg-indigoDye px-4 md:text-[14px] xl:text-[16px] font-bicubik hover:font-semibold hover:text-gray-400"><Link to={'/about-us'}>ABOUT US</Link></li>
                        <li className="py-6 px-4 flex justify-center text-center hover:bg-indigoDye md:text-[14px] xl:text-[16px] font-bicubik hover:font-semibold hover:text-gray-400 group flex-col">
                            <a href="#">Directory</a>
                            <div className="absolute -ml-4  top-[70px] bg-oxfordBlue hidden group-hover:block p-4 ">
                                <div className='flex flex-col'>
                                    <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link to={'directory/map-rental'}>Map & Rental</Link></p>
                                    <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link to={'directory/facilities'}>Facilities</Link></p>
                                </div>

                            </div>
                        </li>
                        <li className="py-6 px-4 flex justify-center text-center hover:bg-indigoDye md:text-[14px] xl:text-[16px] font-bicubik hover:font-semibold hover:text-gray-400 group flex-col">
                            <a href="#">What's on</a>
                            <div className="absolute min-w-[132px] -ml-4 top-[70px] bg-oxfordBlue hidden group-hover:block p-4 ">
                                <div className='flex flex-col'>
                                    <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link to={'whats-on/promo'}>Promo</Link></p>
                                    <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link to={'whats-on/event'}>Event</Link></p>
                                </div>

                            </div>
                        </li>
                        <li className="py-6 px-4 flex justify-center text-center hover:bg-indigoDye md:text-[14px] xl:text-[16px] font-bicubik hover:font-semibold hover:text-gray-400 group flex-col">
                            <a href="#">News</a>
                            <div className="absolute  -ml-4 top-[70px] bg-oxfordBlue hidden group-hover:block p-4 ">
                                <div className='flex flex-col'>
                                    <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link to={'news/press-realese'}>Press Release</Link></p>
                                    <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link to={'news/gallery'}>Gallery</Link></p>
                                </div>

                            </div>
                        </li>
                        <li className="py-6 flex justify-center text-center hover:bg-indigoDye px-4 md:text-[14px] xl:text-[16px] font-bicubik hover:font-semibold hover:text-gray-400"><Link to={'/millenation'}>MILLENATION</Link></li>
                    </ul>
                </div>


                {/* menu mobile */}
                <div className='flex lg:hidden justify-end'>
                    <button onClick={toggleMenu}>
                        <img
                            className="h-8 w-auto"
                            src={isOpenMenu ? Close : Menu}
                            alt="theplazamillennium"
                        />
                    </button>
                </div>



            </div>
            {/* nav item for mobile */}
            {
                isOpenMenu && (
                    <div className='bg-oxfordBlue lg:hidden pt-[40px] h-screen'>
                        <ul className="flex-col">
                            <li className="py-6 flex justify-start text-center hover:bg-indigoDye px-[20px] text-[14px]  font-bicubik hover:font-semibold hover:text-gray-400"><Link onClick={toggleMenu} to={'/'} href="#">HOME</Link></li>
                            <li className="py-6 flex justify-start text-center hover:bg-indigoDye px-[20px] text-[14px]  font-bicubik hover:font-semibold hover:text-gray-400"><Link onClick={toggleMenu} to={'/about-us'}>ABOUT US</Link></li>
                            <li className="py-6 flex justify-start text-center hover:bg-indigoDye px-[20px] text-[14px]  font-bicubik hover:font-semibold hover:text-gray-400 justify-between">
                                {/* <a href={'#'} onClick={toggleHeading('Directory')} >Directory</a>
                                <img
                                    className={`${heading==='Directory'?'rotate-90':'rotate-0'} h-[16px] w-auto sm:h-10`}
                                    src={Arrow}
                                    alt="theplazamillennium"
                                /> */}
                                <h1
                                    className="w-full flex justify-between items-center "
                                    onClick={() => {
                                        heading !== 'Directory' ? setHeading('Directory') : setHeading("");

                                    }}
                                >
                                    Directory
                                    <span className="text-xl md:hidden inline">
                                        <img
                                            className={`${heading === 'Directory' ? 'rotate-90' : 'rotate-0'} h-[16px] w-auto sm:h-10`}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    </span>

                                </h1>
                            </li>
                            <div className={`${heading === 'Directory' ? 'block' : 'hidden'} py-2 px-6`}>
                                <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link onClick={toggleMenu} to={'directory/map-rental'}>Map & Rental</Link></p>
                                <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link onClick={toggleMenu} to={'directory/facilities'}>Facilities</Link></p>
                            </div>
                            <li className="py-6 flex justify-start text-center hover:bg-indigoDye px-[20px] text-[14px]  font-bicubik hover:font-semibold hover:text-gray-400 justify-between">
                                <h1
                                    className="w-full flex justify-between items-center "
                                    onClick={() => {
                                        heading !== 'Whatson' ? setHeading('Whatson') : setHeading("");

                                    }}
                                >
                                    What's on
                                    <span className="text-xl md:hidden inline">
                                        <img
                                            className={`${heading === 'Whatson' ? 'rotate-90' : 'rotate-0'} h-[16px] w-auto sm:h-10`}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    </span>

                                </h1>


                            </li>

                            <div className={`${heading === 'Whatson' ? 'block' : 'hidden'} py-2 px-6`}>
                                <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link onClick={toggleMenu} to={'whats-on/promo'}>Promo</Link></p>
                                <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link onClick={toggleMenu} to={'whats-on/event'}>Event</Link></p>
                            </div>

                            <li className="py-6 flex justify-start text-center hover:bg-indigoDye px-[20px] text-[14px]  font-bicubik hover:font-semibold hover:text-gray-400 justify-between">
                                <h1
                                    className="w-full flex justify-between items-center "
                                    onClick={() => {
                                        heading !== 'News' ? setHeading('News') : setHeading("");

                                    }}
                                >
                                    News
                                    <span className="text-xl md:hidden inline">
                                        <img
                                            className={`${heading === 'News' ? 'rotate-90' : 'rotate-0'} h-[16px] w-auto sm:h-10`}
                                            src={Arrow}
                                            alt="arrow"
                                        />
                                    </span>

                                </h1>


                            </li>

                            <div className={`${heading === 'News' ? 'block' : 'hidden'} py-2 px-6`}>
                                <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link onClick={toggleMenu} to={'news/press-realese'}>Press Release</Link></p>
                                <p className="p-2 font-bicubik text-white text-[12px] hover:font-semibold hover:text-gray-400"><Link onClick={toggleMenu} to={'news/gallery'}>Gallery</Link></p>
                            </div>

                            <li className="py-6 flex justify-start text-center hover:bg-indigoDye px-[20px] text-[14px]  font-bicubik hover:font-semibold hover:text-gray-400"><Link to={'/millenation'}>MILLENATION</Link></li>
                        </ul>
                        <div className='h-[0.5px] mx-[20px] bg-white my-[24px] '></div>
                        <div className="my-6  px-[20px]">
                            <h5 className="uppercase text-left font-bold mb-2.5">FOLLOW US</h5>

                            <div className="flex justify-start mb-6 ">
                                <a href="https://www.facebook.com/theplazamillenniumm" target="_blank" type="button" className="text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
                                    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M31.6663 39.6673H8.33301C4.19967 39.6673 0.833008 36.3007 0.833008 32.1673V8.83398C0.833008 4.70065 4.19967 1.33398 8.33301 1.33398H31.6663C35.7997 1.33398 39.1663 4.70065 39.1663 8.83398V32.1673C39.1663 36.3007 35.7997 39.6673 31.6663 39.6673ZM8.33301 3.00065C5.11634 3.00065 2.49967 5.61732 2.49967 8.83398V32.1673C2.49967 35.384 5.11634 38.0007 8.33301 38.0007H31.6663C34.883 38.0007 37.4997 35.384 37.4997 32.1673V8.83398C37.4997 5.61732 34.883 3.00065 31.6663 3.00065H8.33301Z" fill="white" />
                                        <path d="M22.0245 32.1742V21.9576H25.4329L26.0829 17.7326H22.0245V14.9909C22.0245 13.8326 22.5912 12.7076 24.4079 12.7076H26.2495V9.10755C26.2495 9.10755 24.5745 8.82422 22.9745 8.82422C19.6329 8.82422 17.4495 10.8492 17.4495 14.5159V17.7409H13.7412V21.9659H17.4495V32.1826H22.0162L22.0245 32.1742Z" fill="white" />
                                    </svg>


                                </a>

                                <a href="https://www.instagram.com/theplazamillennium?igsh=cGloOXQ4b3Q5aThk" target="_blank" type="button" className="text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
                                    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M31.6663 39.6673H8.33301C4.19967 39.6673 0.833008 36.3007 0.833008 32.1673V8.83398C0.833008 4.70065 4.19967 1.33398 8.33301 1.33398H31.6663C35.7997 1.33398 39.1663 4.70065 39.1663 8.83398V32.1673C39.1663 36.3007 35.7997 39.6673 31.6663 39.6673ZM8.33301 3.00065C5.11634 3.00065 2.49967 5.61732 2.49967 8.83398V32.1673C2.49967 35.384 5.11634 38.0007 8.33301 38.0007H31.6663C34.883 38.0007 37.4997 35.384 37.4997 32.1673V8.83398C37.4997 5.61732 34.883 3.00065 31.6663 3.00065H8.33301Z" fill="white" />
                                        <path d="M25.4583 13.875C24.7917 13.875 24.25 14.4167 24.25 15.0833C24.25 15.75 24.7917 16.2917 25.4583 16.2917C26.125 16.2917 26.6667 15.75 26.6667 15.0833C26.6667 14.4167 26.125 13.875 25.4583 13.875Z" fill="white" />
                                        <path d="M20.0828 15.4238C17.2828 15.4238 15.0078 17.6988 15.0078 20.4988C15.0078 23.2988 17.2828 25.5738 20.0828 25.5738C22.8828 25.5738 25.1578 23.2988 25.1578 20.4988C25.1578 17.6988 22.8828 15.4238 20.0828 15.4238ZM20.0828 23.7488C18.2911 23.7488 16.8328 22.2905 16.8328 20.4988C16.8328 18.7072 18.2911 17.2488 20.0828 17.2488C21.8745 17.2488 23.3328 18.7072 23.3328 20.4988C23.3328 22.2905 21.8745 23.7488 20.0828 23.7488Z" fill="white" />
                                        <path d="M24.1164 30.8081H15.8831C12.4664 30.8081 9.69141 28.0331 9.69141 24.6164V16.3831C9.69141 12.9664 12.4664 10.1914 15.8831 10.1914H24.1164C27.5331 10.1914 30.3081 12.9664 30.3081 16.3831V24.6164C30.3081 28.0331 27.5331 30.8081 24.1164 30.8081ZM15.8831 12.1331C13.5414 12.1331 11.6331 14.0414 11.6331 16.3831V24.6164C11.6331 26.9581 13.5414 28.8664 15.8831 28.8664H24.1164C26.4581 28.8664 28.3664 26.9581 28.3664 24.6164V16.3831C28.3664 14.0414 26.4581 12.1331 24.1164 12.1331H15.8831Z" fill="white" />
                                    </svg>

                                </a>

                                <a href="https://www.tiktok.com/@theplazamillennium?_t=8nto934crcF&_r=1" target="_blank" type="button" className="text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
                                    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M31.6663 39.6673H8.33301C4.19967 39.6673 0.833008 36.3007 0.833008 32.1673V8.83398C0.833008 4.70065 4.19967 1.33398 8.33301 1.33398H31.6663C35.7997 1.33398 39.1663 4.70065 39.1663 8.83398V32.1673C39.1663 36.3007 35.7997 39.6673 31.6663 39.6673ZM8.33301 3.00065C5.11634 3.00065 2.49967 5.61732 2.49967 8.83398V32.1673C2.49967 35.384 5.11634 38.0007 8.33301 38.0007H31.6663C34.883 38.0007 37.4997 35.384 37.4997 32.1673V8.83398C37.4997 5.61732 34.883 3.00065 31.6663 3.00065H8.33301Z" fill="white" />
                                        <path d="M27.9166 18.5336C27.7499 18.5503 27.5749 18.5586 27.4083 18.5586C25.5499 18.5586 23.8166 17.6253 22.7999 16.0669V24.5419C22.7999 28.0003 19.9916 30.8086 16.5333 30.8086C13.0749 30.8086 10.2666 28.0003 10.2666 24.5419C10.2666 21.0836 13.0749 18.2753 16.5333 18.2753C16.6666 18.2753 16.7916 18.2836 16.9166 18.2919V21.3836C16.7916 21.3669 16.6583 21.3419 16.5333 21.3419C14.7666 21.3419 13.3333 22.7753 13.3333 24.5419C13.3333 26.3086 14.7666 27.7419 16.5333 27.7419C18.2999 27.7419 19.8583 26.3503 19.8583 24.5836L19.8916 10.1836H22.8499C23.1249 12.8336 25.2666 14.9003 27.9249 15.1003V18.5336H27.9166Z" fill="white" />
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>

                )
            }
        </nav >
    )
}

export default Navbar