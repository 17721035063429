class apis {
    host = () => process.env.REACT_APP_API_BASE_URL;
    headers = () => {
      const res = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      return res;
    };
    headersAuth = token => {
      const res = {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      return res;
    };
  }
  
  const api = new apis();
  export default api;
  