import React from "react";
import { Link } from "react-router-dom";
import Playstore from '../assets/images/playstore.png'
import Appstore from '../assets/images/Apple.png'

const Footer = () => {
    return (
        <footer className="text-center bg-indigoDye text-white sticky top-[100vh]">
            <div className="container mx-auto px-6 pt-6">
                <div className="flex flex-col md:flex-row justify-between">
                    <div className="mb-6">
                        <h5 className="uppercase text-left font-bold mb-2.5">FOLLOW US</h5>

                        <div className="flex justify-start md:justify-center mb-6">
                            <a href="https://www.facebook.com/theplazamillenniumm" target="_blank" type="button" className="text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.6663 39.6673H8.33301C4.19967 39.6673 0.833008 36.3007 0.833008 32.1673V8.83398C0.833008 4.70065 4.19967 1.33398 8.33301 1.33398H31.6663C35.7997 1.33398 39.1663 4.70065 39.1663 8.83398V32.1673C39.1663 36.3007 35.7997 39.6673 31.6663 39.6673ZM8.33301 3.00065C5.11634 3.00065 2.49967 5.61732 2.49967 8.83398V32.1673C2.49967 35.384 5.11634 38.0007 8.33301 38.0007H31.6663C34.883 38.0007 37.4997 35.384 37.4997 32.1673V8.83398C37.4997 5.61732 34.883 3.00065 31.6663 3.00065H8.33301Z" fill="white" />
                                    <path d="M22.0245 32.1742V21.9576H25.4329L26.0829 17.7326H22.0245V14.9909C22.0245 13.8326 22.5912 12.7076 24.4079 12.7076H26.2495V9.10755C26.2495 9.10755 24.5745 8.82422 22.9745 8.82422C19.6329 8.82422 17.4495 10.8492 17.4495 14.5159V17.7409H13.7412V21.9659H17.4495V32.1826H22.0162L22.0245 32.1742Z" fill="white" />
                                </svg>


                            </a>

                            <a href="https://www.instagram.com/theplazamillennium?igsh=cGloOXQ4b3Q5aThk" target="_blank" type="button" className="text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.6663 39.6673H8.33301C4.19967 39.6673 0.833008 36.3007 0.833008 32.1673V8.83398C0.833008 4.70065 4.19967 1.33398 8.33301 1.33398H31.6663C35.7997 1.33398 39.1663 4.70065 39.1663 8.83398V32.1673C39.1663 36.3007 35.7997 39.6673 31.6663 39.6673ZM8.33301 3.00065C5.11634 3.00065 2.49967 5.61732 2.49967 8.83398V32.1673C2.49967 35.384 5.11634 38.0007 8.33301 38.0007H31.6663C34.883 38.0007 37.4997 35.384 37.4997 32.1673V8.83398C37.4997 5.61732 34.883 3.00065 31.6663 3.00065H8.33301Z" fill="white" />
                                    <path d="M25.4583 13.875C24.7917 13.875 24.25 14.4167 24.25 15.0833C24.25 15.75 24.7917 16.2917 25.4583 16.2917C26.125 16.2917 26.6667 15.75 26.6667 15.0833C26.6667 14.4167 26.125 13.875 25.4583 13.875Z" fill="white" />
                                    <path d="M20.0828 15.4238C17.2828 15.4238 15.0078 17.6988 15.0078 20.4988C15.0078 23.2988 17.2828 25.5738 20.0828 25.5738C22.8828 25.5738 25.1578 23.2988 25.1578 20.4988C25.1578 17.6988 22.8828 15.4238 20.0828 15.4238ZM20.0828 23.7488C18.2911 23.7488 16.8328 22.2905 16.8328 20.4988C16.8328 18.7072 18.2911 17.2488 20.0828 17.2488C21.8745 17.2488 23.3328 18.7072 23.3328 20.4988C23.3328 22.2905 21.8745 23.7488 20.0828 23.7488Z" fill="white" />
                                    <path d="M24.1164 30.8081H15.8831C12.4664 30.8081 9.69141 28.0331 9.69141 24.6164V16.3831C9.69141 12.9664 12.4664 10.1914 15.8831 10.1914H24.1164C27.5331 10.1914 30.3081 12.9664 30.3081 16.3831V24.6164C30.3081 28.0331 27.5331 30.8081 24.1164 30.8081ZM15.8831 12.1331C13.5414 12.1331 11.6331 14.0414 11.6331 16.3831V24.6164C11.6331 26.9581 13.5414 28.8664 15.8831 28.8664H24.1164C26.4581 28.8664 28.3664 26.9581 28.3664 24.6164V16.3831C28.3664 14.0414 26.4581 12.1331 24.1164 12.1331H15.8831Z" fill="white" />
                                </svg>

                            </a>

                            <a href="https://www.tiktok.com/@theplazamillennium?_t=8nto934crcF&_r=1" target="_blank" type="button" className="text-white leading-normal uppercase hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-9 h-9 m-1">
                                <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M31.6663 39.6673H8.33301C4.19967 39.6673 0.833008 36.3007 0.833008 32.1673V8.83398C0.833008 4.70065 4.19967 1.33398 8.33301 1.33398H31.6663C35.7997 1.33398 39.1663 4.70065 39.1663 8.83398V32.1673C39.1663 36.3007 35.7997 39.6673 31.6663 39.6673ZM8.33301 3.00065C5.11634 3.00065 2.49967 5.61732 2.49967 8.83398V32.1673C2.49967 35.384 5.11634 38.0007 8.33301 38.0007H31.6663C34.883 38.0007 37.4997 35.384 37.4997 32.1673V8.83398C37.4997 5.61732 34.883 3.00065 31.6663 3.00065H8.33301Z" fill="white" />
                                    <path d="M27.9166 18.5336C27.7499 18.5503 27.5749 18.5586 27.4083 18.5586C25.5499 18.5586 23.8166 17.6253 22.7999 16.0669V24.5419C22.7999 28.0003 19.9916 30.8086 16.5333 30.8086C13.0749 30.8086 10.2666 28.0003 10.2666 24.5419C10.2666 21.0836 13.0749 18.2753 16.5333 18.2753C16.6666 18.2753 16.7916 18.2836 16.9166 18.2919V21.3836C16.7916 21.3669 16.6583 21.3419 16.5333 21.3419C14.7666 21.3419 13.3333 22.7753 13.3333 24.5419C13.3333 26.3086 14.7666 27.7419 16.5333 27.7419C18.2999 27.7419 19.8583 26.3503 19.8583 24.5836L19.8916 10.1836H22.8499C23.1249 12.8336 25.2666 14.9003 27.9249 15.1003V18.5336H27.9166Z" fill="white" />
                                </svg>
                            </a>
                        </div>
                    </div>

                    <div className="mb-6 w-full md:w-1/2  md:border-x-[1px] border-y-[1px] border-x-[0px] md:border-y-[0px] border-oxfordBlue flex justify-start md:justify-center">
                        <div className='py-6 md:py-0 '>

                            <h5 className="uppercase  text-left font-bold mb-[20px]">GET THE APP</h5>

                            <div className='flex-col flex'>
                                <Link to={'https://play.google.com/store/apps/details?id=com.millenation'} target="_blank">
                                <div className="w-[300px] md:w-[230px] h-14 px-6 py-2 bg-white rounded-[48px] shadow justify-start items-center gap-2 inline-flex mb-[16px]">
                                    <div className="w-6 h-6 mt-1 relative ">
                                        <div className="w-[16.82px] h-6 left-[1.10px] self-center absolute">
                                            <img
                                                className='flex'
                                                src={Playstore}
                                                alt="playstore"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-col justify-center items-start gap-1 inline-flex">
                                        <div className="text-sky-900 text-xs font-normal  leading-tight">Get it On Google Play</div>
                                        <div className="text-sky-900 text-base font-bold  leading-none">MILLENATION</div>
                                    </div>
                                </div>
                                </Link>
                                <Link to={'https://apps.apple.com/id/app/millenation/id6444555092'} target="_blank">
                                <div className="w-[300px] md:w-[230px] h-14 px-6 py-2 bg-white rounded-[48px] shadow justify-start items-center gap-2 inline-flex">
                                    <div className="w-6 h-6 mt-1 relative ">
                                        <div className="w-[16.82px] h-6 left-[1.10px] self-center absolute">
                                            <img
                                                className='flex'
                                                src={Appstore}
                                                alt="appstore"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex-col justify-center items-start gap-1 inline-flex">
                                        <div className="text-sky-900 text-xs font-normal leading-tight">Get it On App Store</div>
                                        <div className="text-sky-900 text-base font-bold  leading-none">MILLENATION</div>
                                    </div>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="mb-6">
                        <h5 className="uppercase text-left font-bold mb-2.5">INFORMATION</h5>

                        <ul className="list-none mb-0">
                            <li>
                                <p  className="text-left text-oxfordBlue_30 text-[16px] mb-2"><Link to={'/about-us'}>ABOUT US</Link></p>
                            </li>
                            <li>
                                <p  className="text-left text-oxfordBlue_30 text-[16px] mb-2"><Link to={'/contact-us'}>CONTACT US</Link></p>
                            </li>
                            {/* <li>
                                <p  className="text-left text-oxfordBlue_30 text-[16px] mb-2">CAREERS</p>
                            </li> */}
                            <li>
                                <p  className="text-left text-oxfordBlue_30 text-[16px] mb-2"><Link to={'/privacy-policy'}>PRIVACY POLICY</Link></p>
                            </li>
                            <li>
                                <p className="text-left text-oxfordBlue_30 text-[16px] mb-2"><Link to={'/operational-hours'}>OPERATIONAL HOURS</Link></p>
                            </li>
                        </ul>
                    </div>


                </div>
            </div>

            <div className="text-center p-4 bg-oxfordBlue">

                <h1 className="text-white" >COPYRIGHT THE PLAZA MILLENNIUM</h1>
            </div>
        </footer>
    )
}

export default Footer