import React, { useEffect, useState } from 'react'
import http from '../../services/api/http'
import img_parkir from '../../assets/images/facilities/img_parkir.png'
import img_musholla from '../../assets/images/facilities/img_musholla.png'
import img_information from '../../assets/images/facilities/img_information.png'
import img_nurseryRoom from '../../assets/images/facilities/img_nurseryroom.png'
import img_security from '../../assets/images/facilities/img_security.png'
import img_atm from '../../assets/images/facilities/img_atm.png'


export default function Facilities() {

  // const data=[
  //   {
  //     title:'Parkir',
  //     image:img_parkir
  //   },
  //   {
  //     title:'Mushola',
  //     image:img_musholla
  //   },
  //   {
  //     title:'Information',
  //     image:img_information
  //   },
  //   {
  //     title:'Nursery Room',
  //     image:img_nurseryRoom
  //   },
  //   {
  //     title:'Security',
  //     image:img_security
  //   },
  //   {
  //     title:'Atm Center',
  //     image:img_atm
  //   }
  // ]

  const [data, setData] = useState([])

  useEffect(() => {
    getFacilities()

  }, []);

  const getFacilities = async () => {
    let response = null;
    try {
      response = await http.getFacilities();
      if (response.code === 200) {
        setData(response.data)
      } else {
        console.log(response.status)
      }
    } catch (error) {
      response = error?.response?.data || null;

    }
  };

  return (
    <div className='flex w-full justify-center py-[60px]'>
      <div className=' w-10/12 mt-[60px]'>
        <h3 className='font-bicubik text-[20px] md:text-[44px] text-black leading-[58px] tracking-[8.36px] font-normal'>
          Facilities
        </h3>
        <div className="w-[80px] md:w-[120px] h-1 md:h-2 bg-gray-300"></div>

        <div className='mt-[52px] grid grid grid-cols-1 lg:grid-cols-3 gap-x-8 gap-y-10'>

          {/* sample loop. (delete later) */}
          {data.map((item, i) => (
            
            <div className="block bg-white" key={i}>
              <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                <img 
                  className="sm:m-h-64 md:h-64 w-full object-cover" 
                  // src={item.image}
                  // src="https://via.placeholder.com/400x242"
                  src={item?.image === null ? "https://via.placeholder.com/400x242" : `${process.env.REACT_APP_IMG_URL}${item.image}`}
                  alt="" 
                />
                <a href="#">
                  <div
                    className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                  </div>
                </a>
              </div>

              <div className="py-[24px] px-[47px] bg-indigoDye mt-[8px] text-center">
                <h5 className="font-bicubik text-[20px] text-white tracking-[4px] font-normal">
                  {item.title}
                </h5>
                
              </div>
            </div>

          ))}

        </div>
        
        {/* <div className='w-full mt-[52px] flex items-center justify-center'>
          <button className='bg-white border border-2 border-red rounded-md text-red px-[36px] py-[16px] text-[20px]'>
            Load More
          </button>
        </div> */}
        
       

      </div>
    </div>
  )
}