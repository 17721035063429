import React from 'react'
import Home from '../../assets/images/home.svg'

export default function ContactUs() {
  return (
    <div className='flex w-full justify-center py-[60px]'>
      <div className=' w-10/12 mt-[60px]'>
        <h3 className='font-bicubik text-center lg:text-start text-[20px] lg:text-[44px] text-black leading-[58px] tracking-[8.36px] font-normal'>
          Contact Us
        </h3>
        <div className="w-[120px] mx-auto lg:mx-[0px] h-2 bg-gray-300"></div>

        <div className='mt-[52px] grid grid grid-cols-1 lg:grid-cols-2 gap-6'>
          <div > 
            <h4 className='font-bicubik text-center lg:text-start text-[16px] lg:text-[32px] text-oxfordBlue leading-[58px] tracking-[8.36px] font-normal'>
              Information
            </h4>
            <div className="w-[120px] mx-auto lg:mx-[0px] h-1 bg-gray-300 mb-[52px]"></div>
            <div className='relative lg:flex-none flex flex-col items-center justify-center w-full lg:w-11/12 bg-gray_20 p-[24px]'>
              <div className='h-[36px] lg:h-[0px]'></div>
              <h5 className='text-oxfordBlue text-[24px]'>MANAGEMENT OFFICE</h5>
              <div className='h-[8px]'></div>
              <p className='text-oxfordBlue text-[16px] text-center lg:text-start'>Jl. Kapten Muslim No. 111 Medan
                The Plaza Millennium
                Lantai Dasar</p>
              <p className='text-red text-[16px]'>Telp. +62 61 8457 111</p>

              <div className="absolute -top-[28px]  lg:-right-[28px] bg-oxfordBlue shadow-sm shadow-black text-white w-[80px] h-[80px] flex items-center justify-center rounded-full" >
                <img
                  className="relative h-[44px] w-[46px]"
                  src={Home}
                  alt="home"
                />
              </div>
            </div>

          </div>

          <div className=''>
            <h4 className='font-bicubik text-center lg:text-start text-[16px] lg:text-[32px] text-black leading-[58px] tracking-[8.36px] font-normal'>
              Get in Touch With Us
            </h4>
            <div className="w-[120px] mx-auto lg:mx-[0px] h-1 bg-gray-300"></div>
            <div className='mt-[40px] h-12 justify-start items-center gap-4 flex'>
              <h5 className='text-oxfordBlue text-[24px]'>Contact Form</h5>
              <div className='bg-red px-[24px] py-[8px] rounded-[72px]'>
                <h5 className='text-oxfordBlue text-[24px]'>
                  The Plaza Millennium
                </h5>
              </div>

            </div>

            <div>
              <div className="mb-[45px] mt-[32px] w-full">
                <label for="fullname" className="form-label mb-2 text-oxfordBlue text-[20px] font-semibold lg:text-lg">Full Name</label>
                <input
                  type="text"

                  className="
                             form-control
                             block
                             w-full
                             px-3 mt-1
                             py-1.5
                             text-base
                             font-normal
                           text-gray-700
                           bg-white bg-clip-padding
                             border border-solid border-gray-300
                             rounded
                             transition
                             ease-in-out
                             m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                  id="fullname"
                  placeholder="Enter Full Name"
                />
              </div>

              <div className="mb-[45px]  w-full">
                <label for="email" className="form-label mb-2 text-oxfordBlue text-[20px] font-semibold lg:text-lg">Email Address</label>
                <input
                  type="text"

                  className="
                             form-control
                             block
                             w-full
                             px-3 mt-1
                             py-1.5
                             text-base
                             font-normal
                           text-gray-700
                           bg-white bg-clip-padding
                             border border-solid border-gray-300
                             rounded
                             transition
                             ease-in-out
                             m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                  id="email"
                  placeholder="Enter Email Address"
                />
              </div>

              <div className="mb-[45px]  w-full">
                <label for="subject" className="form-label mb-2 text-oxfordBlue text-[20px] font-semibold lg:text-lg">Subject</label>
                <input
                  type="text"

                  className="
                             form-control
                             block
                             w-full
                             px-3 mt-1
                             py-1.5
                             text-base
                             font-normal
                           text-gray-700
                           bg-white bg-clip-padding
                             border border-solid border-gray-300
                             rounded
                             transition
                             ease-in-out
                             m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                  id="subject"
                  placeholder="Enter Subject Message"
                />
              </div>

              <div className="mb-[45px]  w-full">
                <label for="message" className="form-label mb-2 text-oxfordBlue text-[20px] font-semibold lg:text-lg">Message</label>
                <textarea
                  type="text"
                  rows="3" cols="50"
                  className="
                             form-control
                             block
                             w-full
                             px-3 mt-1
                             py-1.5
                             text-base
                             font-normal
                           text-gray-700
                           bg-white bg-clip-padding
                             border border-solid border-gray-300
                             rounded
                             transition
                             ease-in-out
                             m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                  id="message"
                  placeholder="Enter Message"
                />
              </div>

              <div className="mb-[45px]  w-full">
                <label for="phone-number" className="form-label mb-2 text-oxfordBlue text-[20px] font-semibold lg:text-lg">Phone Number</label>
                <input
                  type="text"

                  className="
                             form-control
                             block
                             w-full
                             px-3 mt-1
                             py-1.5
                             text-base
                             font-normal
                           text-gray-700
                           bg-white bg-clip-padding
                             border border-solid border-gray-300
                             rounded
                             transition
                             ease-in-out
                             m-0
                            focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                        "
                  id="phone-number"
                  placeholder="Enter Phone Number"
                />
              </div>
              <div className='justify-end flex'>
                <button className='bg-red text-white px-[36px] py-[16px] text-[20px]'>Submit</button>
              </div>


            </div>

          </div>

        </div>
        {/* <p className='my-[52px] text-[20px]'>Jam Operational The Plaza Millennium dari Pukul 10.00 s/d 22.00, kami selalu memberikankenyamanan berbelanja bagi pengunjung dengan menyediakan security dan kamera CCTV yang selalu stand by 24 Jam.</p>
      <p className='text-[20px]'>Dengan pengunjung rata-rata sebanyak 25.000/hari, yang merupakan potensi pasar yang cukup besar dan berkembang bagi penyewa serta harga barang yang kompetitif dan berkualitas bagi konsumen.</p> */}
      </div>
    </div>
  )
}