import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import http from '../../services/api/http'
import dayjs from 'dayjs';
import img_placeholder from '../../assets/images/placeholder/pildacil.png'


export default function Galery() {

  const [galeryList, setGalleryList] = useState([])
  const [limit, setLimit] = useState(8)

  useEffect(() => {
    getGallery(limit)
  }, []);

  useEffect(() => {
    getGallery(limit)
  }, [limit]);



  const getGallery = async (limit) => {
    let response = null;
    const body = {
      search: '',
      limit: limit
    }
    try {
      response = await http.getGallery(body);
      if (response.code === 200) {
        setGalleryList(response.data)
      } 
    } catch (error) {
      response = error?.response?.data || null;
    }
  };
  return (
    <div className='flex w-full justify-center py-[60px]'>
      <div className=' w-10/12 mt-[60px]'>
        <h3 className='font-bicubik text-[44px] text-black leading-[58px] tracking-[8.36px] font-normal'>
          Gallery
        </h3>
        <div className="w-[120px] h-2 bg-gray-300"></div>

        <div className='mt-[52px] grid grid-cols-1 lg:grid-cols-4 gap-x-8 gap-y-10'>

          {/* sample loop. (delete later) */}
          {galeryList.map((data, i) => (
            
            <div className="block bg-white" key={i}>
              <div className="relative overflow-hidden bg-cover bg-no-repeat" data-te-ripple-init data-te-ripple-color="light">
                <img 
                  className="sm:m-h-64 md:h-64 w-full object-cover" 
                  // src={img_placeholder}
                  src={data?.image === null ? "https://via.placeholder.com/400x242" : `${process.env.REACT_APP_IMG_URL}${data?.image.image}`} 
                  alt={data?.image.alt} 
                />
                 <Link to={`details/${data?.id}/${data?.title.split(' ').join('-')}`} >
                {/* <a href="gallery/details"> */}
                  <div
                    className="absolute bottom-0 left-0 right-0 top-0 h-full w-full overflow-hidden bg-[hsla(0,0%,98%,0.15)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100">
                  </div>
                {/* </a> */}
                </Link>
              </div>

              <div className="py-[16px] px-[47px] bg-[#F3F3F2] text-center">
                <h5 className="font-bicubik text-[16px] text-black tracking-[4px] font-normal mb-[20px]">
                  {data?.title}
                </h5>
                <h5 className="mb-2 text-sm text-black">
                {data?.updated_at!==null?dayjs(data?.updated_at).format('MMM DD, YYYY'):''}
                </h5>
              </div>
            </div>

          ))}

        </div>
        {
          galeryList>8?
          <div className='w-full mt-[52px] flex items-center justify-center'>
          <button onClick={()=>setLimit(limit+8)} className='bg-white border border-2 border-red rounded-md text-red px-[36px] py-[16px] text-[20px]'>
            Load More
          </button>
        </div>
        :
        null
        }
       

      </div>
    </div>
  )
}